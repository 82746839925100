import { FC } from 'react';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { PODIUM_ICON_IMG } from '../../const';
import { Colors } from '../../themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  frostedGlass: {
    // textAlign: 'center',
    width: '100%',
    backgroundColor: '#e4ebeea6',
    backdropFilter: 'blur(17px)',
    zIndex: 112,
    [theme.breakpoints.down('md')]: { top: '40%' },
    [theme.breakpoints.down('xs')]: {
      // top: '32%',
      margin: theme.spacing(2, 0),
      top: '-37vh',
      marginBottom: '-30vh',
      position: 'relative',
      '&>div': {
        padding: theme.spacing(2, 0),
      },
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxWidth: '854px',
      transform: 'translate(-50%, -50%)',
    },
    // '& h1': { textShadow: '0px 8px 22px #002633c2;' },
  },
}));

const ProgramInfo: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.frostedGlass} px={3}>
      <Box display="flex" px={8} py={5}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={8} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography gutterBottom color="primary" variant="h3">
                <i>21 Week Program</i>
              </Typography>
              {/* <TitleText gutterBottom color="primary"> */}
              {/*  <i>$399.00</i> */}
              {/* </TitleText> */}
              <Box style={{ height: '2px', backgroundColor: Colors.Blue }} width="50%" />
              <ul style={{ paddingInlineStart: '28px' }}>
                <Typography color="primary">
                  <li>This program includes a complete 21 week training program.</li>
                </Typography>
                <Typography color="primary">
                  <li>
                    30 Minute consultation video-chat with Jarrett at the beginning of each block.
                  </li>
                </Typography>
                <Typography color="primary">
                  <li>
                    Access to in-depth online videos that show you exactly how to do each exercise.
                  </li>
                </Typography>
                <Typography color="primary">
                  <li>
                    Inside information that expose the secrets of how Jarrett trains the top
                    athletes in the world.
                  </li>
                </Typography>
              </ul>
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box display="flex" flexDirection="column">
              <img
                alt="podium"
                src={PODIUM_ICON_IMG}
                style={{ width: '100%', maxWidth: 187, margin: 'auto' }}
              />
              <Box m="auto" mt={1}>
                <a href="mailto:jarrett.jkpfit@gmail.com">
                  <Button size="large" variant="contained">
                    GET IN TOUCH
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProgramInfo;
