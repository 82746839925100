import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, CircularProgressProps, makeStyles } from '@material-ui/core';

// Add shadow to Spinner
const useStyles = makeStyles({
  svg: {
    // Would be nice but there are artifacts on the spinner. Maybe revisit?
    // filter: 'drop-shadow(0px 0px 2px #04759c25)',
  },
});

interface LoadingPlaceholderProps {
  /** How long to wait before showing the spinner */
  delay?: number;
  /** Whether or not to show the loading placeholder or the children */
  isLoading?: boolean;
  /** Render children while we wait for the 'delay' to fire */
  showChildrenDuringDelay?: boolean;
  /** Should we center the spinner in the container */
  shouldCenter?: boolean;
  /** How big is the spinner in pixels? */
  size?: number;
  /** Whether or not to apply margin. Defaults to auto */
  verticalMargin?: 'sm' | 'md' | 'lg' | string;
}

const LoadingPlaceholder: FC<LoadingPlaceholderProps & CircularProgressProps> = ({
  children,
  className,
  delay,
  disableShrink,
  isLoading,
  shouldCenter = true,
  showChildrenDuringDelay,
  size = 32,
  verticalMargin,
}) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(!delay);
  const { svg } = useStyles();

  // Set a timeout to show spinner if we have a delay
  useEffect(() => {
    if (delay && isLoading) {
      const timeout = setTimeout(() => {
        setShowSpinner(true);
      }, delay);
      return (): void => {
        clearTimeout(timeout);
      };
    }
    if (!isLoading) {
      setShowSpinner(false);
    }
    return (): void => undefined;
  }, [isLoading, delay]);

  // Loading, show children during delayed
  if (isLoading && !showSpinner && showChildrenDuringDelay) {
    return <>{children}</>;
  }

  let vm;
  switch (verticalMargin) {
    case 'sm':
      vm = '8px';
      break;
    case 'md':
      vm = '32px';
      break;
    case 'lg':
      vm = '64px';
      break;
    default:
      vm = 'auto';
      break;
  }

  // Loading, don't show children during delay
  if (isLoading) {
    return (
      <Box
        className={className}
        display="flex"
        flex={1}
        justifyContent={shouldCenter ? 'center' : 'flex-start'}
        marginY={vm}
        minHeight={size}
      >
        <Box m={shouldCenter ? 'auto' : '0 8px'}>
          {showSpinner && (
            <CircularProgress className={svg} disableShrink={disableShrink} size={size} />
          )}
        </Box>
      </Box>
    );
  }
  // Not loading
  return <>{children}</>;
};

export default LoadingPlaceholder;
