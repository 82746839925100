/**
 * Layout for Blog Previews
 */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import TitleText from '../../components/UI/TitleText';

import { ROUTES } from '../../routes/const';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    '& img': {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      maxHeight: '1008px',
    },
  },
  articlePreview: {
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      transition: 'transform 300ms ease-out',
    },
    '&:hover img': {
      transform: 'scale(1.07)',
    },
    '&:hover svg': {
      transform: 'translateX(6px)',
    },
  },
  articlePreviewImage: {
    display: 'flex',
    background: ' #070b0c',
    borderRadius: theme.spacing(0.5),
    height: '300px',
    marginBottom: theme.spacing(3),
    overflow: 'hidden',
    '& img': {
      transition: 'transform 300ms ease-out',
      width: '100%',
      margin: 'auto',
      objectFit: 'cover',
    },
  },
}));

const BLOG_POSTS = gql`
  query GetBlogPosts {
    blogPostCollection {
      items {
        description
        heroImage {
          url
        }
        author
        publishDate
        slug
        tags
        title
      }
    }
  }
`;

const BlogContainer: FC = () => {
  const { data, loading } = useQuery(BLOG_POSTS);
  const posts = data?.blogPostCollection?.items;
  return (
    <Box pb={4}>
      <Container maxWidth="md">
        <Box mb={2}>
          <TitleText color="primary">ON THE BLOG</TitleText>
          <Typography gutterBottom variant="h3">
            TRAINING, HEALTH AND WELLNESS TOPICS
          </Typography>
          <Typography color="primary">
            Find topics to help you get the most out of yourself and your workouts through Jarrett's
            15 years of experience in Personal Training, Sports Science and Athlete Coaching.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {loading
            ? null
            : // @ts-ignore
              posts.map((post) => (
                <ArticlePreview
                  author={post.author}
                  description={post.description}
                  image={post.heroImage.url}
                  key={post.slug}
                  slug={post.slug}
                  tag={post.tags?.[0]}
                  title={post.title}
                />
              ))}
        </Grid>
      </Container>
    </Box>
  );
};

interface ArticlePreviewProps {
  author: string;
  description: string;
  image: string;
  slug: string;
  tag: string;
  title: string;
}
const ArticlePreview: FC<ArticlePreviewProps> = ({
  author,
  description,
  image,
  slug,
  tag,
  title,
}) => {
  const classes = useStyles();
  return (
    <Grid item sm={6} xs={12}>
      <Link to={`${ROUTES.Blog}/${slug}`}>
        <Box className={classes.articlePreview}>
          <Box className={classes.articlePreviewImage}>
            <img alt="preview" src={image} />
          </Box>
          <Box m={0.5}>
            <Typography color="primary" variant="h3">
              {title}
            </Typography>
          </Box>
          <Typography
            gutterBottom
            color="primary"
            style={{ textTransform: 'uppercase' }}
            variant="h4"
          >
            <i>
              {tag}
              {tag ? ' / ' : null}
              By {author}
            </i>
          </Typography>
          <Box>
            <Typography gutterBottom color="primary">
              {description}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <Typography color="primary" variant="h4">
              <i>READ MORE</i>
            </Typography>
            <Box display="flex" ml={1}>
              <ArrowRightAltIcon color="primary" />
            </Box>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
};

export default BlogContainer;
