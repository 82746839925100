import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import ErrorBoundary from './components/ErrorBoundary';
import ErrorMessage from './components/ErrorMessage';
import LoadingPlaceholder from './components/LoadingPlaceholder';

import MainRoutes from './routes/MainRoutes';
import { theme } from './themes/theme';
import { DEFAULT_LOADING_SPINNER_DELAY } from './const';
import reportWebVitals from './reportWebVitals';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const client = new ApolloClient({
  uri: 'https://graphql.contentful.com/content/v1/spaces/kcheqk1vxnwu?access_token=pzXIkKZUnJlp6r3-LhVpMJhZ-mgGGauCSf7Iw5rY4Ok',
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary changeTitle errorComponent={ErrorMessage}>
        <Suspense fallback={<LoadingPlaceholder isLoading delay={DEFAULT_LOADING_SPINNER_DELAY} />}>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <MainRoutes />
            </ApolloProvider>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
