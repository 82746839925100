/**
 * Header
 */
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';

import { BLUE_FULL_LOGO_IMG, WHITE_FULL_LOGO_IMG } from '../../const';
import { ROUTES } from '../../routes/const';
import { Colors } from '../../themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '56px',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(2, 3, 0),
  },
  rootFilled: {
    height: '70px',
    backgroundColor: Colors.Blue,
    padding: theme.spacing(2, 3, 2),
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.lg,
    width: '100%',
  },
  mainLogo: { height: '100%' },
  drawerLogo: { width: '100%', padding: theme.spacing(3) },
  hamburger: {
    border: '1px solid #FFB81C',
    transition: 'all 300ms',
    '&:hover': { borderColor: Colors.LightBlue },
    '& .MuiSvgIcon-root': { fill: '#FFB81C' },
  },
  drawerPaper: {
    width: 240,
    backgroundColor: Colors.LightBlue,
  },
  list: { '& .MuiListItemText-root': { textTransform: 'uppercase' } },
}));
interface HeaderProps {
  filledHeader?: boolean;
}
const Header: FC<HeaderProps> = ({ filledHeader }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column">
      <Box className={classNames(classes.root, { [classes.rootFilled]: filledHeader })}>
        <Box className={classes.container}>
          <Link className={classes.mainLogo} to={ROUTES.Home}>
            {filledHeader ? (
              <>
                <img alt="logo" className={classes.mainLogo} src={WHITE_FULL_LOGO_IMG} />
              </>
            ) : (
              <>
                <Hidden smUp>
                  <img alt="logo" className={classes.mainLogo} src={WHITE_FULL_LOGO_IMG} />
                </Hidden>
                <Hidden xsDown>
                  <img alt="logo" className={classes.mainLogo} src={BLUE_FULL_LOGO_IMG} />
                </Hidden>
              </>
            )}
          </Link>
          <Box display="flex">
            <Grid container alignItems="center" spacing={2} style={{ flexWrap: 'nowrap' }}>
              <Hidden xsDown>
                <Grid item>
                  <Button onClick={(): void => navigate(ROUTES.Home)}>Home</Button>
                </Grid>
                <Grid item>
                  <a href="/#about" style={{ textDecoration: 'none' }}>
                    <Button>About</Button>
                  </a>
                </Grid>
                <Grid item>
                  <Button onClick={(): void => navigate(ROUTES.Program)}>The Program</Button>
                </Grid>
                <Grid item>
                  <Button onClick={(): void => navigate(ROUTES.Blog)}>Blog</Button>
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid item>
                  <MobileNav />
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Box>
      </Box>
      {filledHeader && <Box height={139} width="100%" />}
    </Box>
  );
};

const MobileNav: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDrawer = (): void => {
    setIsOpen(true);
  };
  const handleCloseDrawer = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="open drawer"
        className={classes.hamburger}
        color="inherit"
        edge="start"
        onClick={handleOpenDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleCloseDrawer}
        open={isOpen}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          onClick={handleCloseDrawer}
          onKeyDown={handleCloseDrawer}
          role="presentation"
        >
          <Box>
            <img alt="logo" className={classes.drawerLogo} src={BLUE_FULL_LOGO_IMG} />
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
            <List className={classes.list}>
              <ListItem button onClick={(): void => navigate(ROUTES.Home)}>
                <ListItemText primary="Home" primaryTypographyProps={{ color: 'primary' }} />
              </ListItem>
              <a href="/#about" style={{ textDecoration: 'none' }}>
                <ListItem button>
                  <ListItemText primary="About" primaryTypographyProps={{ color: 'primary' }} />
                </ListItem>
              </a>
              <ListItem button onClick={(): void => navigate(ROUTES.Program)}>
                <ListItemText primary="The Program" primaryTypographyProps={{ color: 'primary' }} />
              </ListItem>
              <ListItem button onClick={(): void => navigate(ROUTES.Blog)}>
                <ListItemText primary="Blog" primaryTypographyProps={{ color: 'primary' }} />
              </ListItem>
            </List>
            <Box display="flex" justifyContent="center" m={3}>
              {/* <SignedIn> */}
              {/*  <UserButton /> */}
              {/* </SignedIn> */}
              {/* <SignedOut> */}
              {/*  <Button onClick={(): void => navigate(ROUTES.Auth.SignIn)} */}
              {/*          variant="contained"> */}
              {/*    Sign In */}
              {/*  </Button> */}
              {/* </SignedOut> */}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
