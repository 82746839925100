export const MuiCssBaseline = (): Record<string, unknown> => ({
  '@global': {
    // a: {
    //   cursor: 'pointer',
    //   color: '#666666',
    //   textDecoration: 'underline',
    // },
    // 'a:hover': {
    //   color: '#999999',
    // },
    img: { display: 'block' },
    'div:focus, p:focus, span:focus': { outline: 'none !important' },
    '.no-wrap': { whiteSpace: 'nowrap' },
  },
});
