import { FC } from 'react';
import { Helmet } from 'react-helmet';

import ENV from '../../env';

export interface IPage {
  title: string;
  noIndex?: boolean;
  description?: string;
}
const Page: FC<IPage> = ({ children, description, noIndex = false, title }) => (
  <>
    <Helmet meta={[{ property: 'og:url', content: `${ENV.APP_URL}${window.location.pathname}` }]}>
      <link href={`${ENV.APP_URL}${window.location.pathname}`} rel="canonical" />
      <title>{title}</title>
      {noIndex ? (
        <meta content="noindex, nofollow" name="robots" />
      ) : (
        <meta content={description} name="description" />
      )}
    </Helmet>
    {children}
  </>
);

export default Page;
