import { Colors } from '../colors';

export const MuiButton = (): Record<string, unknown> => ({
  root: {
    boxShadow: 'none',
    '& label': { color: Colors.Yellow },
  },
  contained: {
    boxShadow: 'none !important',
    backgroundColor: Colors.Yellow,
    color: Colors.Blue,
  },
  text: {
    color: Colors.Yellow,
    '&:hover': { color: Colors.White },
  },
  containedSizeLarge: {
    padding: '12px 32px',
    fontSize: '1rem',
    fontWeight: '800 !important',
  },
});
