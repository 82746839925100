/**
 * @overview a component that catches and logs errors thrown in its children.
 */

import { Component, ErrorInfo, FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
// import * as Sentry from '@sentry/browser';
// import { Scope } from '@sentry/types';

interface ErrorBoundaryProps {
  /**
   * Fallback component to display when uncaught exceptions occur in a component tree:
   * ```
   * function({ error: object, errorInfo: object, showReportDialog: () => void }): ReactNode
   * ```
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorComponent: FC<any>;
  /**
   * Callback for handling any additional logging or async behavior. It does not impact
   * component rendering:
   * ```
   * function(error, errorInfo) {}
   * ```
   */
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  /**
   * Should we change the title of the page to reflect that there was an error
   * ```
   * string
   * ```
   */
  changeTitle?: boolean;
}
interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  eventCuid?: string;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });

    // Use Sentry to track the error
    // try {
    //   Sentry.withScope((scope: Scope) => {
    //     scope.setExtras(errorInfo);
    //     const eventCuid = Sentry.captureException(error);
    //     this.setState({ eventCuid });
    //   });
    // } catch (e) {
    //   // eslint-disable-next-line no-console
    //   console.error(e);
    // }

    // Handle any additional logic we need
    const { onError } = this.props;
    if (typeof onError === 'function') {
      // Wrapped in a promise so we can do this in a non-blocking way
      Promise.resolve().then(() => onError(error, errorInfo));
      // .catch(sentryTrackError);
    }
  }

  render(): ReactNode {
    const { changeTitle = false, children, errorComponent: ErrorComponent } = this.props;
    const { error, errorInfo, eventCuid } = this.state;
    return (
      <>
        {error && changeTitle && (
          <Helmet>
            <title>Error</title>
          </Helmet>
        )}
        {error ? (
          <ErrorComponent
            errorComponentStack={errorInfo?.componentStack}
            errorMessage={error?.message}
            eventCuid={eventCuid}
          />
        ) : (
          children
        )}
      </>
    );
  }
}

export default ErrorBoundary;
