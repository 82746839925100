/**
 * Layout for Blog pages
 */
import { FC } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../components/Header/Header';

import FooterContainer from '../containers/Footer/FooterContainer';
import { Colors } from '../themes/colors';

const useStyles = makeStyles(() => ({
  root: {
    // marginTop: theme.spacing(2),
    backgroundColor: Colors.White,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  mainContainer: {
    backgroundColor: Colors.White,
    position: 'relative',
    zIndex: 1,
    // marginBottom: '100px',
  },
}));
const ArticleLayout: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header filledHeader />
      <Box display="flex" flexDirection="column" height="100%" position="relative">
        <Box className={classes.mainContainer}>{children}</Box>
        <FooterContainer />
      </Box>
    </Box>
  );
};
export default ArticleLayout;
