/**
 * Layout for auth pages
 */
import { FC } from 'react';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import TitleText from '../../components/UI/TitleText';

import ProgramInfo from './ProgramInfo';

import { BIKE_ICON_IMG, FITNESS_ICON_IMG, HEALTH_ICON_IMG, SCIENCE_ICON_IMG } from '../../const';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },

  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '100%',
      maxWidth: '135px',
      marginBottom: theme.spacing(5),
    },
  },
}));
const ProgramContainer: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="md">
        <Box className={classes.root}>
          <Hidden smUp>
            <ProgramInfo />
          </Hidden>
          <TitleText yellow>OUR APPROACH</TitleText>
          <Typography gutterBottom color="secondary" variant="h3">
            HOW WE WORK TO ACHIEVE YOUR GOALS
          </Typography>
          <Typography color="secondary">
            Every athlete wants to win. Back in my racing days I’d do anything to get on the podium.
            But you need a good strength and fitness coach to help. Because when it comes to
            performance, a trainer can make all the difference in every aspect of training.
          </Typography>
          <Box display="flex" mt={10}>
            <Grid container spacing={5}>
              <Grid item sm={6} xs={12}>
                <Box display="flex" flexDirection="column">
                  <Box mb={3}>
                    <Typography align="center" color="secondary" variant="h3">
                      WHAT IS IT
                    </Typography>
                  </Box>
                  <Typography align="center" color="secondary">
                    Training is a tool to stimulate the body. How this is done matters. Exercise
                    selection, load, repetitions, sets, timing, frequency, testing, heart rate and
                    power data are variables that need to be accounted for in order to stay
                    stimulated without feeling annihilated. But, don’t you worry, I’ve done all of
                    this for you.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display="flex" flexDirection="column">
                  <Box mb={3}>
                    <Typography align="center" color="secondary" variant="h3">
                      HOW WE DO IT?
                    </Typography>
                  </Box>
                  <Typography align="center" color="secondary">
                    A mix of on the bike and gym training routines that complement each other so you
                    get the most out of each training day. Based on science, each day is designed to
                    target specific adaptations needed to improve your strength, power, speed and
                    endurance on the bike.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={7}>
            <Typography
              align="center"
              color="secondary"
              style={{ margin: 'auto', maxWidth: '768px' }}
              variant="h3"
            >
              THE PROGRAM IS BROKEN UP INTO 3 BLOCKS EACH CONSISTING OF:
            </Typography>
          </Box>

          <Box mt={7} p={2} style={{ border: '1px solid', borderRadius: '8px' }}>
            <Grid container spacing={5}>
              <TrainingBlock
                description="If you’re not testing, there’s no hard evidence to show improvement. Tests on the bike and in the gym will set a baseline to work from. In this program, I simply input your testing numbers and everything you need to know for your training block is laid out for you. This will occur between each block to keep you moving in the right direction."
                image={HEALTH_ICON_IMG}
                title="TESTING"
              />
              <TrainingBlock
                description="A day designed for recovery/restoration. These routines will aid in performing the gym work better, but without the muscle fatigue the actual gym work creates."
                image={SCIENCE_ICON_IMG}
                title="BODY WEIGHT"
              />
              <TrainingBlock
                description="These days are to build strength, power and speed, but also to combat the natural asymmetries of our body to keep us moving at maximal capacity. From your testing, the loads will be personalized to build a solid strength base, which will carry you throughout the rest of the program."
                image={FITNESS_ICON_IMG}
                title="GYM WORKOUTS"
              />
              <TrainingBlock
                description="These are strategically placed throughout the week, so the legs will be ready for various interval sets and above VO2 max effort days. This will build long lasting endurance and allow you to fine tune yourself for upcoming events."
                image={BIKE_ICON_IMG}
                title="BIKE WORKOUTS"
              />
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box display="flex">
        <Box display="flex" flexDirection="column" m="auto" mb={5} mt={10} px={2}>
          <TitleText align="center">WINNING RESULTS!</TitleText>
          <Box m="auto" mt={1.5}>
            <a href="mailto:jarrett.jkpfit@gmail.com">
              <Button size="large" variant="contained">
                GET IN TOUCH
              </Button>
            </a>
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface TrainingBlockProps {
  description: string;
  image: string;
  title: string;
}
const TrainingBlock: FC<TrainingBlockProps> = ({ description, image, title }) => {
  const classes = useStyles();
  return (
    <Grid item sm={6} xs={12}>
      <Box className={classes.block}>
        <img alt="fitness" src={image} />
        <Typography gutterBottom color="secondary" variant="h3">
          {title}
        </Typography>
        <Typography align="center" color="secondary">
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

export default ProgramContainer;
