/**
 * Layout for Blog pages
 */
import { FC } from 'react';
// @ts-ignore
import Plx from 'react-plx';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from '../components/Header/Header';

import { HERO_IMG } from '../const';
import FooterContainer from '../containers/Footer/FooterContainer';
import { Colors } from '../themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: Colors.White,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  heroImage: {
    width: '100%',
    objectPosition: 'top',
    minHeight: '370px',
    maxHeight: '800px',
    height: '60vh',
    objectFit: 'cover',
  },
  mainContainer: {
    backgroundColor: Colors.White,
    position: 'relative',
    zIndex: 1,
    // marginBottom: '100px',
    '&::after': {
      left: 0,
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'inherit',
      zIndex: '-1',
      top: '0',
      transformOrigin: 'right top',
      transform: 'skewY(10deg)',
    },
  },
  titleText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '854px',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: { top: '40%' },
    [theme.breakpoints.down('xs')]: { top: '32%' },
    '& h1': { textShadow: '0px 7px 24px #00141ba6' },
    // '& h1': { textShadow: '0px 8px 22px #002633c2;' },
  },
}));
const parallaxDataHeroImage = [
  {
    start: 150,
    end: 700,
    properties: [
      {
        startValue: 0,
        endValue: 200,
        unitString: '%',
        property: 'translateY',
      },
    ],
  },
];

const BlogLayout: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header />
      <Box height="100%" position="relative">
        <Plx parallaxData={parallaxDataHeroImage}>
          <img alt="hero" className={classes.heroImage} src={HERO_IMG} />
        </Plx>
        <Box className={classes.mainContainer} style={{ minHeight: '50vh' }}>
          {children}
        </Box>
        <FooterContainer />
      </Box>
    </Box>
  );
};
export default BlogLayout;
