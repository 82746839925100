/*
Site-wide Constants
 */

// How long to wait before we show a loading spinner
export const DEFAULT_LOADING_SPINNER_DELAY = 150;
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_HOUR_IN_MINUTES = 60;
export const ONE_MINUTE_IN_SECONDS = 60;
export const HTML_INFINITY_SYMBOL = <>&#8734;</>;
export const STRING_SEPARATOR = '**';

/**
 * Images
 */
const getImage = (img: string): string => `${process.env.PUBLIC_URL}/images/${img}`;
// Logos
export const BLUE_FULL_LOGO_IMG = getImage('JKP_Logo_Full_Blue.png');
// export const YELLOW_FULL_LOGO_IMG = getImage('JKP_Logo_Full_Yellow.png');
export const WHITE_FULL_LOGO_IMG = getImage('JKP_Logo_Full_White.png');
export const BLACK_FULL_LOGO_IMG = getImage('JKP_Logo_Full_Black.png');
export const JKP_GREY_LOGO_IMG = getImage('JKP_Logo_JKP_Grey.png');
// Icons
export const FITNESS_ICON_IMG = getImage('fitness-icon.png');
export const GOALS_ICON_IMG = getImage('goals-icon.png');
export const HEALTH_ICON_IMG = getImage('health-icon.png');
export const SCIENCE_ICON_IMG = getImage('science-icon.png');
export const WELLNESS_ICON_IMG = getImage('wellness-icon.png');
export const BIKE_ICON_IMG = getImage('bike-icon.png');
export const PODIUM_ICON_IMG = getImage('podium-icon.png');
// Images
export const HERO_IMG = getImage('hero.jpg');
// export const JARRETT_SPRINT_IMG = getImage('jarrett-sprint.jpg');
export const JARRETT_HEADSHOT_IMG = getImage('jarrett-headshot.jpg');
export const JARRETT_HEADSHOT2_IMG = getImage('jarrett-headshot2.jpg');
export const JARRETT_CLIMB_IMG = getImage('jarrett-climb.jpg');
export const WOMAN_SQUAT_IMG = getImage('woman-squat.png');
export const DEADLIFT_IMG = getImage('deadlift.png');
// export const TRAINING_IMG = getImage('training.jpg');
export const CHRIS_SLIDEBOARD_IMG = getImage('chris-slideboard.jpg');
export const GIRL_BALL_IMG = getImage('girl-ball.jpg');
export const GIRL_SITTING_IMG = getImage('girl-sitting.jpg');
// Athletes
export const ELIOT_BIKE_IMG = getImage('eliot-bike.JPG');
export const ELIOT_WHIP_IMG = getImage('eliot-whip.jpg');
export const ELIOT_JUMP_IMG = getImage('eliot-jump.jpg');
export const CHAD_WIN_IMG = getImage('chad-win.jpg');
export const BERNARD_WIN_IMG = getImage('bernard-win.jpg');
export const LUCA_PODIUM_IMG = getImage('luca-podium.jpg');
