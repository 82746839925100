import { ErrorInfo, FC, ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// import * as Sentry from '@sentry/browser';
import ENV from '../../env';

const reloadPage = (): void => window.location.reload();

interface ErrorMessageProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  buttonFunction?: Function;
  buttonTitle?: string;
  errorComponentStack?: Pick<ErrorInfo, 'componentStack'>;
  errorMessage?: Pick<Error, 'message'>;
  message?: string | ReactElement;
  title?: string;
}
const ErrorMessage: FC<ErrorMessageProps> = ({
  buttonFunction = reloadPage,
  buttonTitle = 'Reload Page',
  errorComponentStack,
  errorMessage,
  message = 'Sorry! Something went wrong! If you could try reloading the page, that would be great.',
  title = 'Error',
}) => (
  <Box
    display="flex"
    flex={1}
    flexDirection="column"
    height="100%"
    justifyContent="center"
    margin="auto"
  >
    <Typography gutterBottom align="center" variant="h1">
      {title}
    </Typography>

    <Typography gutterBottom align="center">
      {message}
    </Typography>
    {ENV.IS_NODE_ENV_DEVELOPMENT && (
      <>
        <Typography gutterBottom align="center">
          {errorMessage}
        </Typography>
        <Typography gutterBottom align="center">
          {errorComponentStack}
        </Typography>
      </>
    )}
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item>
        <Button
          color="primary"
          onClick={(): void => buttonFunction()}
          size="small"
          variant="outlined"
        >
          {buttonTitle}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default ErrorMessage;
