/**
 * Layout for an Article
 */
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { Navigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import rehypeRaw from 'rehype-raw';

import TitleText from '../../components/UI/TitleText';

import { ROUTES } from '../../routes/const';

const useStyles = makeStyles(() => ({
  imageContainer: {
    width: '100%',
    // height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#070b0c',
    '& img': {
      height: 'auto',
      maxHeight: '50vh',
      maxWidth: '100%',
      margin: 'auto',
    },
  },
}));
const BLOG_POST = gql`
  query GetBlogPost($slug: String!) {
    blogPostCollection(where: { slug: $slug }) {
      items {
        description
        heroImage {
          url
        }
        author
        body
        slug
        tags
        title
      }
    }
  }
`;

const ArticleContainer: FC = () => {
  const classes = useStyles();
  const params = useParams();
  const { data, loading } = useQuery(BLOG_POST, { variables: { slug: params.blogPost } });
  const post = data?.blogPostCollection?.items?.[0];

  if (!loading && (!post || Object.entries(post).length === 0)) {
    return <Navigate to={ROUTES.Home} />;
  }
  return (
    <>
      <Helmet>
        <title>{post?.title}</title>
        <meta content={post?.description} name="description" />
      </Helmet>
      <Box className={classes.imageContainer}>
        <img alt="hero" src={post?.heroImage?.url} />
      </Box>
      <Container maxWidth="md">
        <Box my={6}>
          <Box mb={1}>
            <TitleText align="center">{post?.title}</TitleText>
          </Box>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            style={{ textTransform: 'uppercase' }}
            variant="h3"
          >
            <b>
              <i>
                {post?.tag}
                {post?.tag ? ' / ' : null}
                By {post?.author && post.author}
              </i>
            </b>
          </Typography>
        </Box>
        <Box mb={5} minHeight="60vh">
          <Typography color="primary">
            {/* @ts-ignore */}
            <ReactMarkdown escapeHtml={false} rehypePlugins={[rehypeRaw]}>
              {post?.body}
            </ReactMarkdown>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default ArticleContainer;
