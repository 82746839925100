import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import Page from '../components/Page/Page';

import { ROUTES } from './const';

import ArticleContainer from '../containers/Article/ArticleContainer';
import BlogContainer from '../containers/Blog/BlogContainer';
import HomeContainer from '../containers/Home/HomeContainer';
import ProgramContainer from '../containers/Program/ProgramContainer';
import ArticleLayout from '../layouts/ArticleLayout';
import BlogLayout from '../layouts/BlogLayout';
import HomeLayout from '../layouts/HomeLayout';
import ProgramLayout from '../layouts/ProgramLayout';

const MainRoutes: FC = () => (
  <Routes>
    <Route
      element={
        <Page title="Home • Jarrett Kolich Performance">
          <HomeLayout>
            <HomeContainer />
          </HomeLayout>
        </Page>
      }
      path={ROUTES.Home}
    />

    <Route
      element={
        <Page title="Program • Jarrett Kolich Performance">
          <ProgramLayout>
            <ProgramContainer />
          </ProgramLayout>
        </Page>
      }
      path={ROUTES.Program}
    />

    <Route
      element={
        <Page title="Blog • Jarrett Kolich Performance">
          <BlogLayout>
            <BlogContainer />
          </BlogLayout>
        </Page>
      }
      path={ROUTES.Blog}
    />

    <Route
      element={
        <Page title="Blog • Jarrett Kolich Performance">
          <ArticleLayout>
            <ArticleContainer />
          </ArticleLayout>
        </Page>
      }
      path={ROUTES.Blog + ROUTES.BlogPost}
    />
  </Routes>
);
export default MainRoutes;
