/**
 * H1 with the ability to change the color
 */
import { FC } from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { Colors } from '../../themes/colors';

interface TitleTextProps {
  yellow?: boolean;
}
const TitleText: FC<TitleTextProps & TypographyProps> = ({ children, yellow, ...rest }) => (
  <Typography {...rest} style={{ color: yellow ? Colors.Yellow : '', ...rest.style }} variant="h1">
    {children}
  </Typography>
);
export default TitleText;
