// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Subscribe: React.FC = () => (
  <Box alignItems="center" display="flex" flexDirection="column">
    <Typography gutterBottom align="center" component="div" variant="h3">
      Get started with the Jarrett Performance Program Today!
    </Typography>
    <Box mt={1.5}>
      <a href="https://buy.stripe.com/cN28wC4ny1NJ2yI000" rel="noopener noreferrer" target="_blank">
        <Button size="large" variant="contained">
          GET STARTED
        </Button>
      </a>
    </Box>
  </Box>
);

export default Subscribe;
