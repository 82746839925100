interface EnvProps {
  IS_NODE_ENV_DEVELOPMENT: boolean;
  APP_URL: string;
  NODE_ENV: string;
}

// Do not place anything here that wouldn't want users to see!
const IS_NODE_ENV_DEVELOPMENT = process.env.NODE_ENV === 'development';
const ENV: EnvProps = {
  IS_NODE_ENV_DEVELOPMENT,
  NODE_ENV: process.env.NODE_ENV ?? 'development',
  APP_URL: process.env.APP_URL ?? 'localhost:5000',
};

export default ENV;
