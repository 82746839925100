import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export { fade, darken } from '@material-ui/core/styles/';

// Color Tool
// ------
// https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=F57C00&primary.color=04759C

export enum Colors {
  Blue = '#003E51',
  LightBlue = '#E4EBEE',
  Yellow = '#FFB81C', // warning
  Red = '#c05e59', // failure
  Black = '#0c0c0c',
  White = '#FFFFFF',
  Inherit = 'inherit',
  Green = '#41835F',
}
// export const namesOfColors = {
//   // [Colors.Orange]: 'Orange',
//   [Colors.Blue]: 'Blue',
//   // [Colors.Green]: 'Green',
//   [Colors.Yellow]: 'Yellow',
//   [Colors.Black]: 'Black',
//   [Colors.White]: 'White',
//   [Colors.Inherit]: 'N/A',
// };

const primary = { main: Colors.Blue };

const secondary = { main: Colors.LightBlue };

const error = { main: Colors.Red };

export const palette: PaletteOptions = {
  primary,
  secondary,
  error,
  text: { primary: Colors.Yellow },
  background: { default: Colors.White },
  common: { black: Colors.Black, white: Colors.White },
  grey: {
    50: '#f6f8f9',
    100: '#fbfbfb',
    200: '#edeff0',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#898989',
    600: '#6c7174', // #657786
    700: '#54575a',
    800: '#495057',
    900: '#3b4144',
    A100: '#f7fafc',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
    // Maybe?
    // Grey1 = '#2F3B43',
    // Grey2 = '#54575a',
    // Grey3 = '#8A939C',
    // Grey4 = '#D9DCDF',
    // Grey5 = '#EAEDEF',
    // Grey6 = '#F6F7F8',
  },
  // Used by `getContrastText()` to maximize the contrast between the background and
  // the text.
  contrastThreshold: 3, // Default 3
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.1, // Default 0.2
  // pageBackground: {
  //   white: Colors.OffWhite,
  //   grey: Colors.AliceBlue,
  // },
};
